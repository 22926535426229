import React from "react";
import Layout from "../../components/Layout";
import RenderContent from "../../components/RenderContent";

export default props => {
  const {
    data: { title, cost, duration, body = [] }
  } = props.pageContext;

  return (
    <Layout>
      <section>
        <h3>{title.text}</h3>
        <p>{cost.text}</p>
        <p>{duration.text}</p>
        <RenderContent content={body} />
      </section>
    </Layout>
  );
};
